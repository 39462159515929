import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Header2 from '../components/Header2';
import newsData from '../data/news.json';
import Modal from '../components/Modal';
import Visioniq from './Visioniq';
import { Link } from 'react-router-dom';

const HomePage = () => {
	const [countdown, setCountdown] = useState<string>('');
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [modalContent, setModalContent] = useState<{
		content: string;
		title: string;
		link: string;
	} | null>(null);

	useEffect(() => {
		const targetDate = new Date('2024-01-26T20:00:00+01:00');

		const calculateCountdown = () => {
			const currentDate = new Date();
			const timeDifference = targetDate.getTime() - currentDate.getTime();

			if (timeDifference > 0) {
				const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
				const hours = Math.floor(
					(timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				const minutes = Math.floor(
					(timeDifference % (1000 * 60 * 60)) / (1000 * 60)
				);
				const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

				setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
			} else {
				setCountdown('Party time!');
			}
		};

		calculateCountdown();

		const intervalId = setInterval(calculateCountdown, 1000);

		return () => clearInterval(intervalId);
	}, []);

	const categoryColors: { [key: string]: string } = {
		needed: '#FF4500',
		interest: '#FF69B4',
		political: '#00FF00'
	};

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible);
	};

	const openModal = (content: string, title: string, link: string) => {
		setModalContent({ content, title, link });
	};
	const closeModal = () => {
		setModalContent(null);
	};

	const getCategoryColor = (category: string) => {
		return categoryColors[category] || '#000';
	};

	const groupedNewsByCategory: { [key: string]: any[] } = {};
	newsData.newsItems.forEach((item) => {
		if (!groupedNewsByCategory[item.category]) {
			groupedNewsByCategory[item.category] = [];
		}
		groupedNewsByCategory[item.category].push(item);
	});

	const sortedNews = Object.keys(groupedNewsByCategory).map((category) => ({
		category,
		items: groupedNewsByCategory[category]
	}));

	return (
		<div className="min-h-screen bg-ocr-abt h-max-full flex flex-col justify-between">
			<Header />

			<div className="lg:absolute left-4 top-14">
				<button
					onClick={toggleDropdown}
					className="flex items-center h-8 pl-3 pr-2 border border-black focus:outline-none"
				>
					<span className="text-sm leading-none font-bold">DEVFAST News</span>
					<svg
						className="w-4 h-4 mt-px ml-2"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
							clipRule="evenodd"
						/>
					</svg>
				</button>
				{dropdownVisible && (
					<div
						className="lg:absolute flex flex-col  mt-1 shadow-lg border px-2 py-1"
						style={{ backdropFilter: 'blur(30px)' }}
						id="dropdown-news"
					>
						{sortedNews.map((category) => (
							<div key={category.category} className="mb-4 w-full">
								<div
									className="text-lg font-bold w-full"
									style={{ color: getCategoryColor(category.category) }}
								>
									<span className="text-sm">{category.category}</span>
								</div>
								{category.items.map((item) => (
									<React.Fragment key={item.id}>
										{item.isModal ? (
											<button
												className="flex items-center h-10 px-3 text-sm hover:bg-gray-200 border-b w-full"
												onClick={() =>
													openModal(item.content, item.title, item.link)
												}
											>
												<span
													className="mr-2"
													style={{
														backgroundColor: getCategoryColor(item.category),
														width: '10px',
														height: '10px',
														display: 'inline-block',
														borderRadius: '50%',
														marginRight: '8px'
													}}
												></span>
												<span className="text-sm text-start">{item.title}</span>
											</button>
										) : (
											<a
												className="flex items-center h-10 px-3 text-sm hover:bg-gray-200 border-b w-full"
												href={item.link}
												target="_blank"
												rel="noopener noreferrer"
											>
												<span
													className="mr-2"
													style={{
														backgroundColor: getCategoryColor(item.category),
														width: '10px',
														height: '10px',
														display: 'inline-block',
														borderRadius: '50%',
														marginRight: '8px'
													}}
												></span>
												<span className="text-sm text-start">{item.title}</span>
											</a>
										)}
									</React.Fragment>
								))}
							</div>
						))}
					</div>
				)}
			</div>

			{modalContent && (
				<Modal
					content={modalContent.content}
					onClose={closeModal}
					title={modalContent.title}
					link={modalContent.link}
				/>
			)}

			<div className="lg:absolute right-4 top-14 flex flex-col gap-3 items-center ">
				<Link to="/me" className=" hover:cursor-pointer">
					<img src="/Thread.png" alt="" height={100} width={100} />
				</Link>
			</div>

			<section className="text-center lg:h-full flex justify-center items-center">
				<div className="relative flex flex-col justify-center items-center w-full ">
					<img
						src="/logo_center.svg"
						alt=""
						className="w-2/3 max-w-sm md:max-w-md lg:max-w-md xl:max-w-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
					/>

					<h1 className="text-5xl md:text-5xl lg:text-7xl xl:text-7xl 2xl:text-9xl font-bold mb-4 yurco-font text-blue relative z-10 shadow-2xl shadow-blue">
						Yurco
					</h1>
				</div>
			</section>

			<Header2 />
		</div>
	);
};

export default HomePage;
