import React from 'react';
import { Link } from 'react-router-dom';

type VisionType = {
	id: number;
	title: string;
	edition: string;
	branding: string;
	description: string;
	categories: string[];
	createdBy: string;
	image: string;
};

const Visioniq = ({ visions }: { visions: VisionType[] }) => {
	return (
		<div className="flex items-center justify-center min-h-screen  bg-blue w-full">
			<div className="flex flex-col items-center justify-around min-h-screen bg-ocr-abt container mx-auto bg-black pt-10 gap-20">
				<section className="text-center flex flex-col justify-center gap-32 w-full items-center">
					<div>
						<h1 className="text-3xl md:text-7xl font-bold mb-4 font-cloth text-green text-center">
							<img src="/LOGO(1).svg" alt="" width={300} />
						</h1>
						<p className="text-red">(blueprint)</p>
					</div>
					<Link to={'/'} className="absolute top-10 left-56">
						<img
							src="./logo_center(5).svg"
							alt=""
							className="w-sm"
							width={50}
						/>
					</Link>

					<Link to={'/me'} className="absolute top-10 right-56">
						<h1 className="text-3xl md:text-4xl font-bold mb-4 font-cloth text-blue ">
							ME
						</h1>
					</Link>
				</section>

				<section className="flex flex-col justify-center gap-32 items-center px-4">
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
						{visions.map((vision: VisionType) => (
							<div key={vision.id} className=" border flex  h-max-20">
								<div className="">
									<img
										src={vision.image}
										alt={`${vision.title} Preview`}
										className="w-full h-32 object-cover mb-4 rounded-md"
									/>

									<div className="">
										<p className="">{vision.description}</p>
									</div>
								</div>
								<div
									style={{ backdropFilter: 'blur(40px)' }}
									className="h-full border-l flex justify-center items-center"
								>
									<h2 className="text-xl font-semibold mb-2 text-blue rotate-90">
										{vision.title}
									</h2>
								</div>
							</div>
						))}
					</div>
				</section>
			</div>
		</div>
	);
};

export default Visioniq;
