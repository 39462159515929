import React, { useState } from 'react';
import { Link } from 'react-router-dom';

type ProjectType = {
	id: number;
	title: string;
	description: string;
	category: string;
	link: string;
	technologies: string[];
	image: string;
};

const ZerosAndOnes: React.FC<{ projects: ProjectType[] }> = ({ projects }) => {
	const [hovered, setHovered] = useState<number | null>(null);

	return (
		<div className="flex items-center justify-center min-h-screen bg-red w-full">
			<div className="flex flex-col justify-around min-h-screen bg-ocr-abt container mx-auto bg-black pt-10 gap-20">
				<section className="text-center flex flex-col gap-32 w-full ">
					<h1 className="text-3xl md:text-4xl font-bold mb-4 font-music text-red text-center">
						ZEROS & ONES
					</h1>
					<Link to={'/'} className="absolute top-10 left-56">
						<img
							src="./logo_center(6).svg"
							alt=""
							className="w-sm"
							width={50}
						/>
					</Link>
					<Link to={'/me'} className="absolute top-10 right-56">
						<h1 className="text-3xl md:text-4xl font-bold mb-4 font-cloth text-red ">
							ME
						</h1>
					</Link>
				</section>

				<section className="flex flex-col justify-center gap-32 items-center px-4">
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
						{projects.map((project: ProjectType, index: number) => (
							<a
								href={project.link}
								target="_blank"
								rel="noopener noreferrer"
								className="linkaleso relative"
								key={project.id}
								onMouseEnter={() => setHovered(index)}
								onMouseLeave={() => setHovered(null)}
							>
								<div className={`p-2 border flex flex-col justify-between `}>
									<div>
										<h2 className="text-lg md:text-xl font-semibold mb-2 text-red text-start uppercase tajtel">
											{project.title}
										</h2>

										<p className="text-xs md:text-base mb-4 text-center">
											{project.description}
										</p>
										<p className="text-gray-300 mb-2 text-end">
											<span className="text-beige">
												{project.technologies.join(', ')}
											</span>
										</p>
									</div>
								</div>
							</a>
						))}
					</div>
				</section>
			</div>
		</div>
	);
};

export default ZerosAndOnes;
