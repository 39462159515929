import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import MeFooter from '../components/MeFooter';

type Props = {};

const Me = (props: Props) => {
	return (
		<div className="flex items-center justify-center min-h-screen  bg-green w-full">
			<div className="flex flex-col items-center justify-around min-h-screen bg-ocr-abt container mx-auto bg-black">
				<section className="text-center flex flex-col justify-center gap-32 w-full">
					<h1 className="text-3xl md:text-7xl font-bold mb-4 font-cloth text-green ">
						ME
					</h1>
					<section>
						<p className=" text-red border-t border-b border-red">
							i like to express myself creatively through programming, design,
							music etc...
						</p>
					</section>
					<Link to={'/'} className="absolute top-10 left-56">
						<img
							src="./logo_center(2).svg"
							alt=""
							className="w-sm"
							width={50}
						/>
					</Link>
				</section>

				<MeFooter />
			</div>
		</div>
	);
};

export default Me;
