import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import HomePage from './pages/HomePage';
import Year from './pages/2025';
import NotFound from './pages/404';
import Me from './pages/Me';
import Visioniq from './pages/Visioniq';
import ZerosAndOnes from './pages/ZerosAndOnes';
import './index.css';
import projects from './data/projects.json';
import visions from './data/visions.json';

const App: React.FC = () => {
	return (
		<AppProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/2025" element={<Year />} />
					<Route
						path="/visioniq"
						element={<Visioniq visions={visions.visions} />}
					/>
					<Route path="/me" element={<Me />} />
					<Route
						path="/zerosandones"
						element={<ZerosAndOnes projects={projects.projects} />}
					/>
					<Route element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</AppProvider>
	);
};

export default App;
