import React from 'react';
import FastMarquee from 'react-fast-marquee';

const Header2 = () => {
	return (
		<header className="w-full bg-black text-green flex items-center py-1">
			<FastMarquee
				gradientColor={[255, 255, 255].join(',').toString()}
				speed={75}
				direction="right"
				pauseOnClick
				className="marquee flex items-center"
			>
				<h1
					className="flex items-center font-zetta  gap-2"
					style={{ marginRight: '70px' }}
				>
					<span className="font-zetta font-extrabold">
						BOUND<span className=" font-zetta font-normal">LESSLY </span> CREAT
						<span className=" font-zetta font-normal">IVE </span> PROGRA
						<span className=" font-zetta font-normal">MMER </span>A
						<span className=" font-zetta font-normal">ND </span>DESI
						<span className=" font-zetta font-normal">GNER </span>{' '}
					</span>
				</h1>
				<img
					src="/wing1.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px' }}
				/>
				<div className="flex gap-2">
					<h1
						className="flex items-center underline"
						style={{ marginRight: '70px' }}
					>
						“F E E L F R E E , T H I N K F R E E”
					</h1>
				</div>
				<img
					src="/wing2.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px' }}
				/>
				<h1
					className="flex items-center font-zetta font-extrabold gap-2"
					style={{ marginRight: '70px' }}
				>
					IN DA <span className=" font-zetta font-normal">AIR </span>
				</h1>
				<img
					src="/wing3.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px' }}
				/>
				<h1
					className="flex items-center font-bold uppercase"
					style={{ marginRight: '70px' }}
				>
					The Unwritten Universe
				</h1>
				<img
					src="/wing4.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px' }}
				/>
			</FastMarquee>
		</header>
	);
};

export default Header2;
