import React from 'react';
import FastMarquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

const Header2 = () => {
	return (
		<header className="relative  w-full bg-black text-green flex items-center py-4">
			<FastMarquee
				gradientColor={[255, 255, 255].join(',').toString()}
				speed={75}
				direction="right"
				pauseOnClick
				className="marquee flex items-center"
			>
				<Link to="/me" className="link indexlink font-cloth ">
					<img
						src="/Thread.png"
						alt=""
						height="72"
						width="72"
						className="bg-green"
					/>
				</Link>
				<img
					src="/wing1.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px', marginLeft: '70px' }}
				/>
				<Link
					to="/2025"
					className="year link indexlink font-year text-6xl md:text-4xl "
				>
					2025
				</Link>
				<img
					src="/wing2.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px', marginLeft: '70px' }}
				/>
				<Link
					to="/visioniq"
					className="link pixel_logo indexlink font-petit text-5xl md:text-3xl"
				>
					VISIONIQ
				</Link>
				<img
					src="/wing3.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px', marginLeft: '70px' }}
				/>
				<Link
					to="https://on.soundcloud.com/9BXGp"
					className="link music indexlink text-4xl md:text-2xl"
					id="music_link"
					target="_blank"
				>
					ME | volume 1
				</Link>
				<img
					src="/wing4.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px', marginLeft: '70px' }}
				/>
				<Link
					to="/zerosandones"
					className="link threed indexlink font-music text-4xl md:text-2xl"
				>
					ZEROS & ONES
				</Link>
				<img
					src="/wing2.svg"
					alt=""
					className="rotating-svg"
					style={{ marginRight: '70px', marginLeft: '70px' }}
				/>
			</FastMarquee>
		</header>
	);
};

export default Header2;
