import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

type Props = {};

const Year = (props: Props) => {
	return (
		<div className="flex items-center justify-center min-h-screen  bg-yellow w-full">
			<div className="flex flex-col items-center justify-around min-h-screen bg-ocr-abt container mx-auto bg-black">
				<section className="text-center flex flex-col justify-center gap-32 w-full items-center">
					<h1 className="text-3xl md:text-7xl font-bold mb-4 font-year text-yellow text-center">
						2025
					</h1>
					<Link to={'/'} className="absolute top-10 left-56">
						<img
							src="./logo_center(7).svg"
							alt=""
							className="w-sm"
							width={50}
						/>
					</Link>
					<Link to={'/me'} className="absolute top-10 right-56">
						<h1 className="text-3xl md:text-4xl font-bold mb-4 font-cloth text-yellow ">
							ME
						</h1>
					</Link>
				</section>

				{/* <MeFooter /> */}
			</div>
		</div>
	);
};

export default Year;
