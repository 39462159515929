import React from 'react';

interface ModalProps {
	content: string;
	onClose: () => void;
	title: string;
	link: string;
}

const Modal = ({ content, onClose, title, link }: ModalProps) => {
	const paragraphs = content.split('<br>');

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center modal-animation max-h-2/3">
			<div className="absolute inset-0 bg-gray-900 opacity-25 filter blur-md"></div>
			<div
				className="z-50 w-1/3 min-h-60 p-6 shadow-lg modal-content flex flex-col justify-between"
				style={{ backdropFilter: 'blur(40px)' }}
			>
				<div>
					<div className="flex justify-between items-center mb-4  border-b-2">
						<h2 className="text-xl font-semibold">{title}</h2>
						<button
							onClick={onClose}
							className="text-gray-400 hover:text-gray-600 focus:outline-none"
						>
							<svg
								className="w-6 h-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								></path>
							</svg>
						</button>
					</div>

					{paragraphs.map((paragraph, index) => (
						<p key={index} className="mb-4">
							{paragraph}
						</p>
					))}
				</div>

				<div className="flex items-end">
					<a
						href={link.toString()}
						target="_blank"
						className=" text-green bg-black underline border border-green px-2 rounded-full"
					>
						{`link to source`}
					</a>
				</div>
			</div>
		</div>
	);
};

export default Modal;
